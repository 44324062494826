<template>
    <carousel :items-to-show="1" :wrap-around="true">
        <slide v-for="post in blogPosts" :key="post.id" :slide-width="'200px'">
            <div class="carousel__item flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div class="flex-shrink-0">
                    <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
                </div>
                <div class="flex flex-1 flex-col justify-between bg-white p-6 cursor-pointer" @click="selectedTestimonial = post">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
                        <p class="mt-3 text-base text-gray-500">{{ (post.preview).substring(0,275) }}...</p>
                    </div>
                    <div class="mt-6 flex items-center">
                        <div class="flex-shrink-0">
                            <a :href="post.author.href">
                                <img class="h-10 w-10 rounded-full" :src="post.author.imageUrl" :alt="post.author.name" />
                            </a>
                        </div>
                        <div class="ml-3">
                            <p class="text-sm font-medium text-gray-900">
                                <a :href="post.author.href" class="hover:underline">{{ post.author.name }}</a>
                            </p>
                            <div class="flex space-x-1 text-sm text-gray-500">
                                <time>{{ post.subtitle }}</time>
                                <!--                                        <span aria-hidden="true">&middot;</span>
                                                                        <span>{{ post.readingLength }} read</span>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slide>

        <template #addons>
            <navigation />
            <pagination />
        </template>
    </carousel>

    <!-- Testimonial Dialog -->
    <DialogModal :show="selectedTestimonial !== null" @close="closeModal">
        <template #title>
            {{selectedTestimonial.title}}
        </template>

        <template #content>
            {{selectedTestimonial.preview}}
        </template>

        <template #footer>
            <SecondaryButton @click="closeModal" class="cursor-pointer">
                Close
            </SecondaryButton>
        </template>
    </DialogModal>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import DialogModal from '@/Components/DialogModal.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import {ref} from "vue";
const props = defineProps({
    blogPosts: Object,
})
const selectedTestimonial = ref(null)

const closeModal = () => {
    console.log('close modal')
    selectedTestimonial.value = null;
};
</script>

<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import { ref, reactive, nextTick, onMounted } from 'vue';
import LandingPageLayout from '@/Layouts/LandingPageLayout.vue';
const landingPage = ref(null);


import {
    ArrowPathIcon,
    Bars3Icon,
    CloudArrowUpIcon,
    CogIcon,
    LockClosedIcon,
    ServerIcon,
    ShieldCheckIcon,
    ChevronRightIcon,
    XMarkIcon,
    ArrowTopRightOnSquareIcon,
    CheckIcon,
    CubeTransparentIcon,
    PencilIcon,
    DocumentMagnifyingGlassIcon,
    DocumentCheckIcon,
    ShareIcon,
    RocketLaunchIcon
} from '@heroicons/vue/24/outline'
import LandingPageAircraftAsset from '@/Components/LandingPageAircraftAsset.vue';
import QuickQuote from '@/Components/Quoting/QuickQuote.vue';

import BlogCarousel from '@/Components/BlogCarousel.vue';
import PrimaryButton from "@/Components/PrimaryButton.vue";
const selectedTestimonial = ref(null)

const closeModal = () => {
    console.log('close modal')
    selectedTestimonial.value = null;
};

const a = ref(false);

const abTest = () => {
    let x = (Math.floor(Math.random() * 2) === 0);
    if(x){
        a.value = true;
    }
}

onMounted(() => hideseoheaders());

const email = ref('');
const register = () => {
    window.open('/register?email='+email.value);
}

const hideseoheaders = () => {

    let header1 = document.getElementById('head1');
    let header2 = document.getElementById('head2');
    let header3 = document.getElementById('head3');

    setTimeout(() => {
        header1.style.display = 'none';
        header2.style.display = 'none';
        header3.style.display = 'none';
    }, 3000);
}

const checkList = [
    'Protect your logbooks and records from damage or loss. 60% of the value of your aircraft is from accurate logbooks',
    'Increase the resale value of your aircraft and decrease purchase due diligence time',
    'Improve safety and transparency.  Keep track of due maintenance, AD’s, FARs, and Service Bulletins',
    'Catch and prevent errors and omissions from mechanics',
    'Quickly and safely share aircraft records with others. Banks, insurance companies, mechanics, etc',
]

const features = [
    {
        name: 'In-House Scanning',
        description: 'The only provider with full time employees trained specifically in handling your delicate aircraft records.',
        icon: DocumentCheckIcon,
    },
    {
        name: 'FAA Compliant AC 120-78A',
        description: 'Electronic signatures and record keeping in accordance with <a class="font-medium text-indigo-600 hover:text-indigo-500" href="https://www.faa.gov/regulations_policies/advisory_circulars/index.cfm/go/document.information/documentID/1043396" target="_blank">AC 120-78B</a>.',
        icon: ShieldCheckIcon,
    },
    {
        name: 'Powerful OCR and Search',
        description: 'The most powerful OCR for automatic digitization of all of your records coupled with hand-typing of maintenance entries so you can find everything.',
        icon: DocumentMagnifyingGlassIcon,
    },
    {
        name: 'Easy to Share',
        description: 'Share your data with anyone using predefined roles to easily control access.',
        icon: ShareIcon,
    },
    {
        name: 'Maintenance Tracking',
        description:
            'Powerful and customizable maintenance tracking with user specific email alerting. Automatic time updates via FlightAware.',
        icon: CogIcon,
    },
    {
        name: 'Flexibility',
        description: 'Service levels from full DIY to full concierge. Plus all of your data is always yours and easily exportable.',
        icon: CubeTransparentIcon,
    },
]
const blogPosts = [

    {
        id: 1,
        title: 'CEO of Iridium Communications, Inc.',
        href: '#',
        subtitle: 'SOCATA TBM 700 and 930 Owner',
        datetime: '2020-02-12',
        imageUrl:
            'https://logixpublic.s3.amazonaws.com/partnerships/N217DC.jpg',
        preview:
            'The logbooks for my 1993 TBM were a mess, just given the myriad of entries over the years, various forms and tickets, service centers involved, etc. Reviewing the history of the airframe, engine and avionics was a real challenge. Signing up for PLANELOGIX was not only easy, but has been a great experience. Everything has been high quality, the company is very responsive, and I believe the value of my airplane has increased because future buyers can see exactly what they are buying. I\'m a happy customer and recommend the service to anyone.',
        author: {
            name: 'Matt Desch',
            imageUrl:
                'https://logixpublic.s3.amazonaws.com/partnerships/N217DC.jpg',
            href: '#',
        },
        readingLength: '1 min',
    },
    {
        id: 2,
        title: 'Former MMOPA President',
        href: '#',
        subtitle: 'Piper Malibu Owner',
        datetime: '2020-03-16',
        imageUrl:
            'https://logixpublic.s3.amazonaws.com/partnerships/N4372B.jpg',
        preview:
            'I wish to report an unexpected and clear benefit from the PLANELOGIX records that I purchased 2 years ago. My 1984 PA-46 Piper Malibu has 3400 hours total time and 550 hours on its third engine. This model has a Gar-Kenyan hydraulic pump to operate the landing gear and flaps. On a recent 8 hour round trip flight to New England it developed recurrent hydraulic pump shutdown due normal operation of the hydraulic pump safety shutdown circuit. The hydraulic fluid level was slightly low at my first stop and, after filling it, did not decrease during subsequent stops. This suggests an internal leak. My PLANELOGIX records nicely show replacement of the hydraulic pump O-rings in 2001, 2006, and 2011. My next annual inspection is scheduled in early August. This definitely helped with troubleshooting. My mechanic will complete repairs this week. I thought you\'d enjoy hearing about another clear benefit from your system. Keep up the good work.',
        author: {
            name: 'Jim Yankaskas',
            imageUrl:
                'https://logixpublic.s3.amazonaws.com/partnerships/N4372B.jpg',
            href: '#',
        },
        readingLength: '',
    },
  {
    id: 3,
    title: 'Repeat Customer',
    href: '#',
    subtitle: 'Cessna 414 Owner',
    datetime: '2020-03-10',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/N2689G.jpg',
    preview:
        'My Cessna 414 is a complex aircraft with a long complex history. I found it so difficult to keep track of everything and wasn\'t sure if I had it all in order. I found PLANELOGIX and it fit the bill perfectly. I flew my log books down (not far for me) and they returned them in 4 days. Then the transcribing so character searches possible, and setting up the tracking items for maintenance and AD compliance. There were two ADs that were out of compliance and thankfully easy inspections. My pre buy audited the logbooks, but this is like having that done all of the time. The A&P or I upload new entries and they automatically update everything, and check to ensure the wording is correct for FAA compliance. Now I have paper logbooks and 100% accurate electronic copies as well. Lose logbooks and there goes most of the plane\'s value. I added the Executive Binder to keep in the aircraft for easy reference away from home and they send new pages as new entries are made, fabulous. This is so worth the money, and should increase resale value. Upon resale, there is a setting for selling the plane and allowing access to view the logbooks. I visited with one owner, Will, and he was fantastic and I could see the passion they have for GA and this business. I\'m not sure I\'d buy another airplane unless they had this service; their logbooks had better be in very good order otherwise.\n' +
        '\n' +
        'I cannot recommend this service enough, from single engine to turboprops (jets too), it\'s a must have. I tried to keep up with everything, but missed those ADs. Now, I know I\'m flying an airworthy aircraft. Gives me peace of mind.',
    author: {
      name: 'Caiolinn Ertel',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/N2689G.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 4,
    title: 'Brigadier General- USAF',
    href: '#',
    subtitle: 'Beechcraft A36 Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/N3868Y.jpg',
    preview:
        'Rob and his team at PLANELOGIX have developed a great product. Their meticulous attention to detail and logical presentation organized my 25+ years of maintenance records into one easy to use binder and web site that will allow my mechanic quick and easy access to all critical maintenance data.',
    author: {
      name: 'Todd Canterbury',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/N3868Y.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 5,
    title: 'Piper PA-24-250 Owner',
    href: '#',
    subtitle: 'Piper PA-24-250 Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/ben.png',
    preview:
        'I\'ve had the plane in and out of the shop several times over the past few months for various stuff - avionics upgrades, radio bug-hunting, some brake work - and I\'m now getting ready for the annual. I can\'t believe how useful PLANELOGIX is. Every time I have a question about my records, or need to document something, or find the last shop who worked on the radio - it does exactly what I need, and quickly gives me exactly the info I have to have. My A&P was shocked at how comprehensive the AD tracking is, and how easy it is to demonstrate compliance by pulling the scans of relevant entries. Thanks again for an incredible product!',
    author: {
      name: 'Ben Barnett',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/ben.png',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 6,
    title: 'Owner N555SP 1969 Baron D55',
    href: '#',
    subtitle: 'Owner N555SP 1969 Baron D55',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/n555sp.jpg',
    preview:
        'I wanted to let you know that everybody that has viewed my listing on your site is astounded with the quality of the maintenance entries/service and I wanted to say thank you and great job. People who were looking online for years said they had never seen anything like it.',
    author: {
      name: 'Bill Page',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/n555sp.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 7,
    title: 'Beechcraft C55 Owner',
    href: '#',
    subtitle: 'Beechcraft C55 Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/gary.jpg',
    preview:
        'Will and company is great to work with and I am very pleased with the quality and organization of PLANELOGIX. All my logs are online, in a binder, and in a searchable file. If you are like me and have multiple logbooks, yellow tags, AD\'s, 337\'s, etc, etc, etc, this is a great investment. PLANELOGIX brings it all together.',
    author: {
      name: 'Gary White',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/gary.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 8,
    title: 'Beechcraft F33A Owner',
    href: '#',
    subtitle: 'Beechcraft F33A Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/sok.jpg',
    preview:
        'I bought your service last year and love it. Best thing I ever did.',
    author: {
      name: 'Jim Sok',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/sok.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 9,
    title: 'Beechcraft F33A Former Owner',
    href: '#',
    subtitle: 'Beechcraft F33A Former Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/jblackwell.jpg',
    preview:
        'I most definitely believe having the PLANELOGIX logbooks was a great benefit in facilitating the sale of my aircraft. It was primarily in anticipation of a future sale that I purchased your service. The first thing every prospective buyer wants to see is the logbooks. Having them online and in PDF format works beautifully. Thanks for the good service.',
    author: {
      name: 'James Blackwell',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/jblackwell.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
  {
    id: 10,
    title: 'Piper Meridian Owner',
    href: '#',
    subtitle: 'Piper Meridian Owner',
    datetime: '2020-02-12',
    imageUrl:
        'https://logixpublic.s3.amazonaws.com/partnerships/phimes.jpg',
    preview:
        'Wow, what a great job! I love the briefcase and the binder - they are first class and very classy! Count on me for a recommendation any time!',
    author: {
      name: 'Paul Himes',
      imageUrl:
          'https://logixpublic.s3.amazonaws.com/partnerships/phimes.jpg',
      href: '#',
    },
    readingLength: '1 min',
  },
    {
        id: 11,
        title: 'Beechcraft F33A Owner',
        href: '#',
        subtitle: 'Beechcraft F33A Owner',
        datetime: '2020-02-12',
        imageUrl:
            'https://logixpublic.s3.amazonaws.com/partnerships/jblackwell.jpg',
        preview:
            'Recently I purchased an \'81 Beech F33A which has over 4,000 TT hours, with extensive log books and maintenance records. Since these documents are irreplaceable, I thought it would be a good idea to make photocopies of everything. But then I saw an ad on the Bonanza Society web page for PLANELOGIX. Given the daunting nature of trying to duplicate everything myself, I decided to investigate the service provided by PLANELOGIX. This turned out to be the best money I have ever spent on the airplane. They copied everything, transcribed the data into a digital document and even verified the 337\'s on file with the FAA. I am totally impressed with the job they did and I have peace of mind knowing that I have multiple backups of all my records. I wholeheartedly recommend this service to other airplane owners.',
        author: {
            name: 'James Blackwell',
            imageUrl:
                'https://logixpublic.s3.amazonaws.com/partnerships/jblackwell.jpg',
            href: '#',
        },
        readingLength: '1 min',
    },
    {
        id: 12,
        title: 'Bonanza Owner',
        href: '#',
        subtitle: 'Bonanza P35 Owner',
        datetime: '2024-10-02',
        imageUrl:
            'https://logixpublic.s3.amazonaws.com/partnerships/E1.jpeg',
        preview:
            'An older aircraft with a glass cockpit has so many mandatory individual Airworthiness and AD tracking items, all at different intervals, that it is nearly impossible to stay legally compliant without PLANELOGIX. In today’s legal environment, PLANELOGIX is an absolute must.',
        author: {
            name: 'Erik Anderson',
            imageUrl:
                'https://logixpublic.s3.amazonaws.com/partnerships/E1.jpeg',
            href: '#',
        },
        readingLength: '1 min',
    },
]

const emit = defineEmits(['openOnboardingForm']);

const showOnboardingForm = ref(false);
const openOnboardingForm = () => {
  emit('openOnboardingForm');
  console.log('openOnboardingForm from welcome');
  showOnboardingForm.value = true;

};

</script>
<template>

    <LandingPageLayout title="Welcome" ref="landingPage" :openOnboardingForm="showOnboardingForm">



        <div class="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
            <div class="mx-auto max-w-7xl lg:px-8 items-center">
                <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                    <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                        <div class="lg:py-24">
                            <quick-quote v-if="false">
                              <template #actionbutton>
                                <span class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                                      <span v-if="$page.props.projectQueueStatus.toLowerCase() === 'open'" class="rounded-full bg-gradient-to-r from-logix-light-blue to-logix-dark-blue px-3 py-0.5 text-sm font-semibold leading-5 text-white">Project Queue Open</span>
                                      <span v-if="$page.props.projectQueueStatus.toLowerCase() === 'slow'" class="rounded-full bg-gradient-to-r from-yellow-300 to-amber-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white" title="We're pretty busy!">Project Queue Open</span>

                                      <span v-if="$page.props.projectQueueStatus.toLowerCase() === 'full'" class="rounded-full bg-gradient-to-r from-amber-500 to-red-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white" title="We're pretty busy!">Project Queue Open</span>
                                      <span class="ml-4 text-sm">Generate a quote today</span>
                                      <ChevronRightIcon class="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                              </span>
                              </template>
                            </quick-quote>

                            <h1 class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                <span class="block">Protect Your</span>
                                <span class="block bg-gradient-to-r from-blue-300 to-blue-500 bg-clip-text pb-3 text-transparent sm:pb-5">Aircraft Value</span>
                            </h1>
                            <h1 class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl hidden" v-if="a">
                                <span class="block">The best way to</span>
                                <span class="block bg-gradient-to-r from-blue-300 to-blue-500 bg-clip-text pb-3 text-transparent sm:pb-5">Digitize Your Aircraft Records</span>
                            </h1>
                            <h1 class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl hidden" v-else>
                                <span class="block">The Essential App</span>
                                <span class="block bg-gradient-to-r from-blue-300 to-blue-500 bg-clip-text pb-3 text-transparent sm:pb-5">for Aircraft Owners</span>
                            </h1>
                          <p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl mb-5 "><strong>Turn-key aircraft maintenance record digitization. The easiest way to protect and increase the value of your aircraft. </strong></p>
                            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-white">
                                <li v-for="item in checkList" class="flex gap-x-3">
                                    <CheckIcon class="h-6 w-5 flex-none text-green-400" aria-hidden="true" />
                                    {{ item }}
                                </li>
                            </ul>
                            <p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl mt-5 ">PLANELOGIX provides the absolute best in customer service and aircraft record management. Being a PLANELOGIX customer means never worrying about losing your logbooks or keeping up with maintenance. Join us today!</p>
                            <div class="mt-10 sm:mt-12 text-center">
                                <primary-button v-if="landingPage" @click="openOnboardingForm()" class="h-10"><h3>Start your PLANELOGIX service <em><strong>TODAY</strong></em></h3></primary-button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 -mb-16 sm:-mb-48 lg:relative ">
                        <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <LandingPageAircraftAsset/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Feature section with screenshot -->
        <!--                <div class="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
                            <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                                <div>
                                    <h2 class="text-lg font-semibold text-cyan-600">Serverless</h2>
                                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">No server? No problem.</p>
                                    <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla.</p>
                                </div>
                                <div class="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
                                    <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5" src="https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg" alt="" />
                                </div>
                            </div>
                        </div>-->

        <!-- Feature section with grid -->
        <div class="relative bg-white py-16 sm:py-24 lg:py-32">
            <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 class="text-lg font-semibold text-cyan-600">Manage Easier</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you need to streamline your ownership experience</p>
                <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">Even if someone else is managing your aircraft, whether that's a mechanic, maintenance shop, or management company, PLANELOGIX serves as the necessary glue to maintain record organization and fidelity between all stakeholders.</p>
                <div class="mt-12">
                    <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        <div v-for="feature in features" :key="feature.name" class="pt-6">
                            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                <div class="-mt-6">
                                    <div>
                        <span class="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-logix-light-blue to-logix-dark-blue p-3 shadow-lg">
                          <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                                    </div>
                                    <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">{{ feature.name }}</h3>
                                    <p class="mt-5 text-base text-gray-500" v-html="feature.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Integrations section -->

        <div class="bg-white py-24 sm:py-32 bg-gradient-to-r from-logix-light-blue to-logix-dark-blue">
            <div class="mx-auto max-w-7xl  px-6 lg:px-8">
                <h2 class="text-center text-lg font-semibold text-gray-300">You're in Great Company</h2>
                <h2 class="text-center leading-8 text-white text-3xl font-bold tracking-tight sm:text-4xl pb-3">Integrations and Partnerships</h2>
                <div class="bg-white -mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl lg:grid-cols-6">
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/about/mm.jpg" alt="double-m" width="158" height="48" />
                    </div>
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/about/spruce.jpg" alt="aircraft-spruce" width="158" height="48" />
                    </div>
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/about/sweeps_logo.png" alt="aopa-sweeps" width="158" height="48" />
                    </div>
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/home/airsync_logo_tm_gray.png" alt="airsync" width="158" height="48" />
                    </div>
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/home/airbly_gray.png" alt="airbly" width="158" height="48" />
                    </div>
                    <div class="bg-gray-400/10 p-6 sm:p-10">
                        <img class="max-h-12 w-full object-contain" src="https://logixpublic.s3.amazonaws.com/home/FA_Logo_BLK.jpg" alt="airbly" width="158" height="48" />
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white py-24 sm:py-32" v-if="1===2">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 class="text-center leading-8 text-gray-900 text-3xl font-bold tracking-tight sm:text-4xl">Integrations and Partnerships</h2>
                <div class="mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-1 md:grid-cols-1 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://logixpublic.s3.amazonaws.com/home/aircraft_spruce-logo.jpg" alt="Aircraft Spruce" width="158" height="48" />
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://logixpublic.s3.amazonaws.com/home/airsync_logo_tm.png" alt="Airsync" width="158" height="48" />
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://logixpublic.s3.amazonaws.com/home/airbly.jpg" alt="Airbly" width="158" height="48" />
                </div>
            </div>
        </div>
        <!-- Testimonial section -->
        <div class="bg-gradient-to-r from-logix-light-blue to-logix-dark-blue pb-16 lg:relative lg:z-10 lg:pb-0" v-if="1===2">
            <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
                <div class="relative lg:-my-8">
                    <div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                        <div class="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none">
                            <img class="object-cover lg:h-full lg:w-full" src="https://logixpublic.s3.amazonaws.com/home/aircraft_spruce-logo.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="relative lg:-my-8">
                    <div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                        <div class="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full bg-white">
                            <img class="object-cover lg:h-full lg:w-full" src="https://logixpublic.s3.amazonaws.com/home/airsync_logo_tm.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="relative lg:-my-8">
                    <div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                        <div class="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                            <img class="object-cover lg:h-full lg:w-full" src="https://logixpublic.s3.amazonaws.com/home/airbly.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="mt-12 lg:col-span-2 lg:m-0 lg:pl-8" v-if="1===2">
                    <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                        <blockquote>
                            <div>
                                <svg class="h-12 w-12 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                </svg>
                                <p class="mt-6 text-2xl font-medium text-white">The logbooks for my 1993 TBM were a mess, just given the myriad of entries over the years, various forms and tickets, service centers involved, etc. Reviewing the history of the airframe, engine and avionics was a real challenge. Signing up for PLANELOGIX was not only easy, but has been a great experience. Everything has been high quality, the company is very responsive, and I believe the value of my airplane has increased because future buyers can see exactly what they are buying. I'm a happy customer and recommend the service to anyone.</p>
                            </div>
                            <footer class="mt-6">
                                <p class="text-base font-medium text-white">Matt Desch</p>
                                <p class="text-base font-medium text-cyan-100">CEO of Iridium Communications, Inc</p>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>

        <!-- Blog section -->
        <div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div class="relative">
                <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 class="text-lg font-semibold text-cyan-600">Learn</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hear It From Our Customers</p>
                    <p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">We have an amazing and unique customer base. Read about what they have to say about us!</p>
                    <p class="mt-6 text-sm leading-5 text-cyan-600">Click the quote to read more!</p>
                </div>
                <div class="mx-auto mt-5 max-w-md px-4 sm:max-w-lg sm:px-6 lg:max-w-2xl">
                    <BlogCarousel :blog-posts="blogPosts"></BlogCarousel>
                </div>
                <!--<div class="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">

                    <div v-for="post in blogPosts" :key="post.id" class="flex flex-col overflow-hidden rounded-lg shadow-lg">
                        <div class="flex-shrink-0">
                            <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
                        </div>
                        <div class="flex flex-1 flex-col justify-between bg-white p-6 cursor-pointer" @click="selectedTestimonial = post">
                            <div class="flex-1">
                                    <p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
                                    <p class="mt-3 text-base text-gray-500">{{ (post.preview).substring(0,275) }}...</p>
                            </div>
                            <div class="mt-6 flex items-center">
                                <div class="flex-shrink-0">
                                    <a :href="post.author.href">
                                        <img class="h-10 w-10 rounded-full" :src="post.author.imageUrl" :alt="post.author.name" />
                                    </a>
                                </div>
                                <div class="ml-3">
                                    <p class="text-sm font-medium text-gray-900">
                                        <a :href="post.author.href" class="hover:underline">{{ post.author.name }}</a>
                                    </p>
                                    <div class="flex space-x-1 text-sm text-gray-500">
                                        <time>{{ post.subtitle }}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>



        <!-- CTA Section -->
        <div class="relative bg-gray-900">
            <div class="relative h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 grid  md:gird-cols-1 lg:grid-cols-2 content-center">
                <img class="" src="https://logixpublic.s3.amazonaws.com/home/cessna.png" alt="" />
                <!--<img class="" src="https://logixpublic.s3.amazonaws.com/home/bell-429-front.png" alt="" />-->
                <img class="" src="https://logixpublic.s3.amazonaws.com/home/ka350i-360-1.png" alt="" />
                <!--<div aria-hidden="true" class="absolute inset-0 bg-gradient-to-r from-logix-light-blue to-blue-500 mix-blend-multiply" />-->
            </div>
            <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                <div class="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 class="text-lg font-semibold text-gray-300">Hands-On Customer Support</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help</p>
                    <p class="mt-3 text-lg text-gray-300">No matter how old, or what kind of aircraft you operate, we can help! We pride ourselves in strong customer support coupled with a robust technology stack.</p>
                    <div class="mt-8">
                        <div class="inline-flex rounded-md shadow" v-if="1===2">
                            <a href="#" class="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50">
                                Visit the help center
                                <ArrowTopRightOnSquareIcon class="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LandingPageLayout>


</template>


<!-- main logo svg styling -->
<style type="text/css">
.st0{fill:url(#SVGID_1_);}
.st1{fill:url(#SVGID_2_);}
.st2{fill:url(#SVGID_3_);}
.st3{opacity:0.26;fill:url(#SVGID_4_);}
.st4{opacity:0.74;fill:url(#SVGID_5_);}
.st5{opacity:0.74;fill:url(#SVGID_6_);}
.st6{opacity:0.33;fill:url(#SVGID_7_);}
.st7{fill:#5A8DC4;}
.st8{fill:#5D616C;}
.st9{opacity:0.74;fill:url(#SVGID_8_);}
.st10{opacity:0.33;fill:url(#SVGID_9_);}
.st11{fill:#60BF8D;}
.st12{fill:url(#SVGID_10_);}
.st13{fill:url(#SVGID_11_);}
.st14{opacity:0.83;fill:url(#SVGID_12_);}
.st15{fill:url(#SVGID_13_);}
</style>

<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 719.34 612" style="enable-background:new 0 0 719.34 612;" xml:space="preserve">

                                <g id="Layer_1">
</g>
        <g id="Layer_2">
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="89.5175" y1="130.8985" x2="572.0487" y2="442.0172">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
        <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <path class="st0" d="M353.04,93.47l-1.08-1.32l-0.15,0.07l-0.68-0.75l0.55-1.13l0.45-0.05l0.1-1.09l-0.91-3.18l-1.35-1.65
		l-0.86-0.37l-0.48,0.36l-1.16-0.39l-1.23-1.26l-0.69-5.66l0.48-0.36l-0.2-1.11l-0.37-0.88l-0.85-0.53l-0.47,0.2l-1.91-2.01
		l-1.13-0.7l-0.52-0.82l-0.59-3.47l-0.07-0.86l-4.31-25.57l-5.19-7.15l-1.4-1.03l-1.42-0.88l-0.51,0.67l-0.52,0.82l-0.71-0.44
		l-2.08-0.15l-3.05,0.72l-0.88-0.22l-0.99-2.25l0.48-2l-0.48-1.28l-0.85-0.53l-2.21-0.39l-6.03,14.09l-0.5,2.3l0.74,1.77l-0.02,1.95
		l-0.68,0.89l-0.78,0.34l-0.7,1.2l0.95,7.79l-1.04,1.64l-0.16,1.86l-2.55,1.85l-0.39,1.07l0.64,2.85l-0.17,0.22l-1.27-0.79
		l-0.01,1.79l-0.52,0.82l-0.69-0.6l-0.95-1.08l-1.38,0.45l-0.78,0.34l-1-0.46l-0.82-0.84l-0.54,0.98l-0.99-0.61l-0.32,0.29
		l0.74,1.77l-0.89,3.37l-23.64-7.97l-17.4-5.89l-0.23-0.8l-0.74-0.13l-1.34-0.02l-4.52,2.1l-4.65,3.65l-0.8,0.65l0.34,1.19
		l-0.93,2.04l-0.93,0.4l-4.66,2.02L235.66,70l0.4,2.21l0.4,0.57l1.15,0.55l0.8,1.15l0.06,1.02l-0.94,0.56l0.29,1.81l0.84,2.32
		l-0.53,2.61l-2.8-0.43l-4.38,2.19l-3.07-0.76l-6.99-2.05l-2.18-2.34l-2.08-1.78l-3.58-1.73l-3.75-1.51l-4.11-0.76l-1.96,0.25
		l-0.91,1.89l-0.15,1.7l2.4,4.93l0.38,0.73l0.15,1.73l-0.53,0.98l-1.06,0.16l-1.24,2.18l-6.07,2.78l-2.27,0.39l-3.4,1.32l-0.59-0.04
		l-0.37-0.88l-0.57-0.35l-0.89,1.58l-2.77,0.9l-1.2,0.07l-7.12-0.5l-3.15,0.17L172.61,94l-4.37,2.19l-1.2,0.07l-1.12-0.86L163.65,94
		l-1.3-0.48l-3.53,1.08l-1.25-1.1l-2.46-2.51l-0.75,0.03l-0.96-0.92l0.82-0.8l-0.55-0.51l-1.95-1.54l-4.11-4.19l-0.82-0.84
		l-2.02-0.76l0.07-0.78l0.36-0.76l2.54-3.33l0.96-0.71l0.13-3.19l0.93-2.04l2.44-0.61l0.23-2.63l0.47-0.2l0.12-1.4l1.38-0.45
		l0.44,1.75l0.41,0.42l0.91-0.09l0.48-0.36l0.41-1.22l-0.19-2.9l0.58-3.24l-0.66-2.7l-0.14-5.16l-2.96-13.78l-3.84-3.86l-1.65,0.12
		l-2.02,0.87l-5.34,4.7l-0.42-0.26l-0.4-0.57l-1.77-1.92l-0.51-0.97l-0.56-2.14l0.84-2.75l0.97-0.87l2.4-0.15l0.69-1.04l0.24-1
		l0.41-1.22l1.19,0.08l1.21-1.87l0.44-4.96l-0.49-2.92l-0.58-1.84l-1.25-2.74l-0.34-1.19l0.39-1.06l1.61,0.35l0.32-0.29l-1.52-4.71
		l-1.48-1.9l-4.49-4.92l-3.34-2.73l-1-2.25l-2.63-2.29l-2.46-2.51l-1.72-2.54l-1.23,0.38l-1.47-0.26l-1.66,1.91L122-9.07l0.2,1.11
		l0.57,0.35l0.2,1.1l-0.59-0.04l-1.19-0.08l-0.9,0.09l-1.11,0.78L118-4.35L118.09-2l0.1,2.19l-1.34,1.62l-1.64-0.04l-0.13-1.88
		l0.89-1.58l-0.52-2.61l0.35-0.6l-0.09-0.71l-0.75,0.03l-0.99,1.02l-0.45,1.68l-0.66,0.73l-0.99-0.61l-1.84,0.5l-0.69,1.04
		l-0.38,0.91l-1.58,0.98l-0.42,3.01l-0.46,3.48l-0.46,1.84l-1.37,1.93l-1.13,0.94l0.13,1.88l0.89,5.13l-0.94,2.2l0.79,2.94
		l2.03,7.47l1.41,5.95l-0.36,4.03l0.77,1.46l-0.06,0.62l-0.62,0.27l-0.79,3.92l-0.97,2.51l-1.25,2.33l-2.77,4.33l-2.15,0.63
		l-0.78,0.34l-0.92,0.25l-2.21-0.39l-1.19-0.08L92,56.55l-1.7-2.69l-0.67-0.9l-0.46-1.59l-0.47-4.87l-0.46-1.59l-1.16-2.03
		l-1.01-3.74l0.32-3.57l0.05-2.26l0.21-0.69l-0.19-1.26l0.49-0.51l-1.4-6.11l-0.2-7.81l1.85-4.08l0.8-0.65l0.28-4.89l-0.32-3.14
		l0.53-0.98l1.86-0.81l0.36-2.39l0.32-3.57l2.05-2.98l0.1-1.08l0.59-1.6l0.96-0.71l2.28-3.82l0.5-2.31l-0.36-1.04l-0.72-0.28
		l-0.62,0.27l-0.68,0.89l-0.61,0.11l-0.24,1l0.02,1.48l-0.68,0.89l-1.07,0.32l-0.38,0.91l-1.19-0.08l-2.44,0.61l-2.51,3.02
		l-2.08-0.15l-0.53-0.82l0.39-1.06l0.8-2.29l2.03-2.67l2.95-1.28l0.31-0.13l0.35-0.6l0.19-2.17l2.12-1.96l1-1.18l1.1-2.26l1.66-1.91
		l0.67-2.53l0.45-0.05l0.09,2.35l0.75,1.61l0.93-0.4l1.06-1.8l2.28,1.25l-0.11,1.24l-1.92,1.43l0.37,0.89l0.68,0.75l4.31-1.42
		l0.51-0.67l0.29-1.62l2.02,0.76l1.8,1.61l0.74,0.13l0.48-0.36l1.34,0.02l0.71,0.44l3.49-0.61l1.08,1.32l1.02,0.3l4.62,3.52l1.68,3
		l0.82,0.84l0.48-0.36l-0.26-2.12l0.76-0.18l1.78,1.76l3.18,1.16l1.12,0.86l1.23,1.26l1.94,0.06l0.57,0.35l0.23,0.79l0.98,0.77
		l1.85,0.99l1.24-0.54l-0.05-1.17l-0.64-1.21l-0.71-0.44l-3.04-1.07l-2.57-3.06l-1.02-1.94l-1.28-4.07l-1.46-2.05l-3.58-1.73
		l-0.89-0.06l-1.83-1.3l-0.61,0.11l-0.82-0.84l-1.15-3.82l0.1-1.09l1-1.18l-0.5-1.13l-1.46-0.41l-3.14,0.01l-11.6-3.93l-3.79,0.6
		l-0.68,0.89l-0.76,0.18l0.05-0.62l-1.09-1.17l-0.44-0.11l-0.99-0.62l-1.08-1.32l-1.53-1.28l-3.16-1.47l-3.55-7.11l-4.75-5.4
		l-1.02-0.3l-1.54-1.12l-2.44,0.61l-0.52,0.82l-0.4-2.21l0.81-2.44l0.8-0.65l1.38-0.45l2.98-1.59l1.01-1.33l0.58,0.2l2.12-0.32
		l-0.1-0.55l-0.96-0.92l-3.75-1.51l-2.37-0.17l-3.61,2.01l-1.44-0.57l-4.36,2.04l-3.57-0.09l-4.99-0.97l-1.15-0.55l-0.69,1.04
		l-2.84,0.04l-1.36,0.14l-2.63-0.65l-1.44-0.57l-1.42-2.52l-1.27-0.79l-2.52-0.25l0.95-2.35l0.08-0.93l0.65-0.58l0.14-1.55
		l-0.36-1.04l-1.15-0.55l-6.69-0.39l-3.59,0.06l-2.14-1.16l-1.67-1.37l-1.21-1.57l9.25-3.26l6.58-3.44l1.68-0.43l3.83,0.58
		l2.02-0.87l1.77,0.28l2.32,0.79l4.34-0.09l2-0.72l-3.13-1.78l-1.63-0.19l-1.72-2.54l-0.79-1.15l-1.1-1.01l-7.09-4.24l-1.12-2.5
		l-0.67-0.91l-4.99,0.67l-1.88-0.68l-1.84-1.14l-1.18-1.88l-0.74-1.77l-2.76-2.53l-0.69-2.39l-1.37-1.34l-0.75,0.03l-1.58,0.98
		l-1.2-1.57l-0.33-2.99l-0.51-0.97l-0.59-0.04l-1.04-1.79l0.66-0.73l-0.85-0.53l-3.3-3.19l-5.26-2.94L18-127.94l-3.55-2.04
		l0.07-0.78l-0.48-1.28l-2.22-1.87l-2.15-1.01l-0.75-1.61l-1.81-1.45l-1.02-0.31l-2.5-3.84l0.38-2.55l-0.45-3.39l0.1-4.52
		l-2.45-2.67l-1.84-1.14l-0.52,2.46l-0.54,4.41l-24.5-15.05l-40.51-26.46l-17.15-12.12l-24.18-16.97l-56.89-42.19l-36.2-29.67
		l-35.36-28.99l-13.23-10.83l-11.25-9.6l-29.01-26.52l-17.51-16.43l-18.46-17.51l-0.17,3.65l-0.02,1.95l1.56,0.97l-0.11,2.88
		l-1.35,1.78l-0.96-0.93l-0.59-0.04l0.06,1.02l1.36,6.57l0.65,4.49l-0.24,1l-2.64,1.14l-0.89,1.58l-0.79,0.49l-1.52,3.64l-1.78-0.12
		l-0.78-1.3l0.07-0.78l0.49-0.51l0.22-0.84l-0.06-1.02l0.15-1.7l0.76-1.82l1.51-0.21l0.38-0.91l0.14-4.98l-0.6-1.68l-0.67-0.9
		l-1.84-1.14l-2-2.71l-3.57-3.52l-4.42-5.69l-8.31-12.36l-0.67,2.53l-0.74,1.66l-0.47,3.63l1.08,6.39l-0.43,1.53l-0.78,3.77
		l-0.01,1.79l0.12,2.04l-0.64,2.22l-0.11,1.24l-0.19,2.17l1.36-0.14l0.38,0.73l-0.03,0.31l1.16,2.03l-1.88-0.68l-0.32,0.29
		l-0.96-0.92l-0.45,0.05l-0.12,3.03l0.96,0.93l0.46,1.59l-0.79,0.49l-0.08,0.93l0.09,0.71l-1.85,0.65l-0.09-0.71l0.18-0.38
		l0.21-0.69l-0.02-1.48l-0.27-0.33l-0.44-0.11l-0.05,0.62l-0.08,0.93l-0.52,0.82l-0.77,1.97l-0.07,0.78l0.41,0.42l0.69,0.59
		l2.13,2.96l0.26,0.49l-0.88-0.22l-1.37-1.35l-1.32-0.32l-0.07,0.78l-0.66,2.37l-1.25,2.33l-3.3,6.95l-2.73,5.5l-2.82,3.16
		l-4.23,8.99l-3.51,4.2l-2.07,3.13l-3.8,2.39l-0.58-0.2l-0.53,2.62l-0.78,0.34l-0.89,1.58l-0.8,0.65l-1.19-0.08l-0.97,0.87
		l0.02,6.55l-1.41,2.4l-0.15,1.71l-1.03,1.49l0.63,4.65l0.63,1.37l52.36,49.52l-8.88,23.72l8.87-23.71l-52.36-49.52l-0.8,0.65
		l-1.22,2.02l0.77,3.1l-0.9,5.16l-1.48,1.53l-1.23,0.38l-0.65,2.21l-0.11,1.24l-5.65,3.04l-2.13,2.11l-0.04,2.1l-0.49,0.51
		l0.06,2.66l0.4,2.21l0.77,1.45l0.33,1.35l0.05,1.17l1.33,5.25l0.05,4.45l-0.52,0.82l-1.47,1.38l-1.52,3.64l0.15,1.72l0.26,2.13
		l-1.31,1.31l1.59,7.36l0.9,3.34l2.53,5.17l-0.63,7.13l-1.18,1.56l1.9,2.16l1.83,4.57l1.91,2.01l0.65-0.58l0.1-1.09l1.71-0.74
		l0.41,0.42l-0.53,2.62l0.09,2.35l0.68,4.18l-0.31,1.78l-0.48,0.35l-1.97-3.02l-0.21-0.95l0.22-2.48l-0.46-1.59l-0.6-0.05l-0.7,2.84
		l-0.74-0.13l0.13,1.88l-0.51,0.67l-0.73,6.58l0.64,2.85l2.07,3.58l2.17,2.49l0.88,1.86l-0.57,3.08l-1.72,0.9l-0.88-0.22l-0.93,0.4
		l-0.72,4.79l2.68,6.89l0.12,2.04l0.68,2.38l0.08,2.51l3.63,11.25l1.56,2.61l-0.32,1.93l-0.42-0.26l-0.48,0.35l-0.05,2.26l1.62,1.98
		l0.35,2.83l-0.51,0.67l-0.62,1.9l-0.87,3.06l-1.12,0.78l-0.14,1.55l1.55,2.76l2.51,3.68l2.12,1.32l6.22,7.3l2.21,2.02l2.1,3.27
		l1.17,5.31l2.33,4.06l2.04,2.25l3.5,2.66l1.14,2.34l-0.24,1l0.46,1.59l-0.87,1.42l1.01,2.1l2.28,1.25l3.09,5.52l1.3,3.92l1.53,2.91
		l2.36,8.82l-0.42,6.45l-0.2,0.53l0.41,2.05l0.28,0.18l0.37,0.88l-0.29-0.02l-0.52,0.82l0.26,2.12l0.23,0.79l34.63,25.43l-1.54,3.95
		l49.72,65.87l30.22,24.65l13.03,9.72l1.76-8.07l24.61,18.88l2.65,3.77l0.53,2.45l0.88,3.49l2.65,3.77l7.38,16.19l6.53,10.6
		l0.47,1.44l0.3,1.66l-0.29,1.62l0.48,1.28l1.31,3.76l-0.7,6.27l0.26,2.12l2.15,6.08l9.86,15.12l6.78,9.44l1.96,1.54l1.48,0.1
		l1.75-1.2l1.37-0.29l3.8-5.82l1.63-1.6l1.19,0.08l2.48,2.36l0.58,0.2l0.53-0.98l0.79-0.49l1.36,1.5l1.19,1.72l4.05,3.17l1.08,1.32
		l2.48,2.36l1.47,0.26l3.08,4.04l0.53,2.46l0.54,0.66l0.21,0.95l0.55,0.51l1.33,1.81l1.59,3.93l2.85,4.88l1.32,3.6l0.03,1.33
		l3.59,15.15l0.49,2.92l4.53,9.52l0.29,1.81l3.17,6.38l0.94,1.23l1.25,2.74l0.33,1.35l-0.65,4.01l0.99,2.25l0.01,4.91l0.99,2.25
		l2.99,10.04l0.09,2.34l2.52,1.89l2.67,3.62l3.29,3.35l4.78,6.73l6.74,4.84l1.4,1.03l3.43,5.08l1.76,2.07l1.91-1.27l1.52,1.43
		l0.62-0.27l0.1-1.09l-1.5-1.59l0.34-0.45l-1.38-2.98l-1.44-10.71l-0.8-4.42l2.19-4.53l-0.12-2.03l-0.4-0.57l-0.41-0.42l-0.26-0.48
		l0.04-0.47l0.31-0.13l2.4-0.14l1.87-4.24l-1.09-1.17l-0.2-2.75l1.27-0.85l1.45,2.21l2.73-0.43l0.94-2.2l-1.18-1.88l0.68-0.89
		l0.54,0.66l0.69,0.6l0.63-0.42l0.81,0.99l0.85,0.53l0.73,0.13l0.2-0.53l0.19-2.17l0.34-0.44l0.72,0.28l0.26,0.49l0.69,0.59
		l3.92,1.29l0.44,0.11l0.21-0.69l-0.24-0.64l-1.87-2.47l0.08-0.93l3.63,1.11l0.64-0.42l1.02,0.3l0.26,0.49l-0.47,0.2l0.47,1.44
		l0.57,0.35l0.76-0.18l5.69,1.56l9.78-0.8l0.64-2.22l4.6-1.4l0.2-0.53l-1.59-3.94l0.62-1.91l2.94,0.52l0.57,0.35l-0.64,2.22
		l0.21,0.95l3.3,1.55l0.48,1.28l6.79,0.94l3.86,1.91l0.15-0.07l-0.1-0.55l10.77,4.89l4.49,4.91l7.93,6.56l4.68,2.74l0.62-0.27
		l-1.26-2.59l3.23-1.1l0.99,0.62l0.17,1.42l0.4,0.57l1.67,1.37l0.59,0.04l0.96,0.93l-0.37,0.75l0.26,0.49l0.11,0.4l0.57,0.35
		l0.71,2.08l2.03,2.4l2.08,1.78l3.37,7.49l2.49,2.2l0.8,1.15l2.42,1.34l2.34-1.16l0.72,0.28l0.64,1.21l1.61,0.35l0.69,0.59l1.1,2.81
		l0.96,0.92l2.57-0.37l-0.07-0.86l-0.38-0.73l1.02-3.13l1.34,0.01l0.8,1.15l0.41,2.06l4.49,4.92l2.3,2.73l0.1,3.98l4.28,0.53
		l1.16,0.39l0.69-1.04l-0.61-1.52l-5.17-5.66l-2.94-3.95l-0.73-1.92l0.31-1.77l2.57-0.37l2.55-1.85l1.51-0.21l0.24-1l-0.36-1.04
		l0.55-1.13l-0.61-1.52l-0.71-0.44l-1.19-0.08l-3.24-0.54l-1.49,1.69l-0.78,0.34l-0.82-0.84l-1.08-1.32l-0.17-1.41l1.23-0.38
		l0.49-0.51l-0.07-0.86l-0.86-0.37l-1.84-1.14l-1.19-0.08l-1.81,0.19L45.72,274l-1.37-1.34l-0.99-2.25l0.26-1.31l2.92-0.97l1.05,0
		l0.98,0.77l3.52,4.14l4.02,3.48l1.97,1.39l3.22-0.95l7.14,1.98l1.64,1.68l2.49,0.56l0.36,1.04l1.52,1.43l0.59,0.04l0.76-0.18
		l0.9-0.09l2.32,2.43l0.68-0.89l0.79-3.92l1.25-2.33l1.3,3.91l-0.24,1l0.5,2.77l1.46,3.69l1.54,1.12l1.29,0.63l2.03-1.03l0.37,0.09
		l-0.11,1.26l0.45,0.65l2.36,0.64l0.8-0.18l1.39-2.36l0.91,0.87l0.89,0.55l0.45-0.05l0.6,0.52l-0.17,0.45l-1.72,0.44l0.33,0.63
		l1.36,0.17l1.84,0.98l0.01,0l7.45,1.84l0.91,0.57l-0.05,0.52l-1.44,0.47l0.62,0.76l3.1,2.26l2.85,3.07l3.09,3.88l0.94,0.77
		l-0.05-1.24l0.68-0.16l0.6,1.31l1.8,1.77l0.38,0.54l0.09,0.3l-0.75,0.03l0.26,0.89l3.67,5.71l0.17,1.37l-0.23,0.98l-0.3,0.51
		l-0.44-0.83l-0.26-1.14l-0.44,0.75l0.06,1.09l0.88,0.89l5.69,1.73l0.03,0.01l0.35-0.59l1.97,1.06l4.88-1.22l3.02,1.87l0.08-0.87
		l-0.35-1.02l1.01-1.59l4.81,2.34l6.82,8.46l0.04,1.16l1.71,2.71l1.32,3.59l0.01,0.02l4.21,6.37l0.44,1.72l1.2,2.1l3.4,1.95
		l2.29,5.97l0.63,1.03l-0.38,1.04l0.75,5.05l-0.86,4.67l-1.59,4.42l-0.23,4.31l0.45,1.79l2.58,3.79l1.07-1.26l-0.8-1.34l-0.45-3.18
		l0.16-0.33l1.49,0.62l0.9,3.33l0.8,0.5l0.41-1.24l0.39-0.04l0.59,0.96l-0.24,1.1l-0.82,0.94l-0.82,0.8l-1.28,2.63l-0.9-0.06
		l-0.14,1.62l0.9,1.89l1.41,2.5l1.89,7.39l3.63,5.06l0.51,0.13l0.26-1.29l1.03,0.64l0.61,2.94l0.02,1.47l1.01,3.94l1.48,2.08
		l1.63,1.66l2.08,10.09l0.01,0.03l0.68,0.93l3.75,3.32l1.46,1.87l3.73,8.36l1.73,2.74l0.71,0.44l1.04,1.28l0.17,1.15l-0.79-0.2
		l-0.66-0.72l-0.46-0.29l-0.64-0.05l-0.23,0.75l0.53,1.2l2.13,1.83l1.21-0.07l1.71,0.9l6.49,1.23l1.68-1.93l0.21-0.56l-0.52-4.26
		l0.3-1.75l1.53-2.42l1.3,1.15l-0.97-7.9l0.73-3.15l0.28-6.53l0-0.01l-0.83-5.93l-0.69-2.4l-3.83-8.92l-2.07-8.64l-2.95-7.05
		l0.19-0.52l-0.18-1.45l-0.89-3.32l0.11-1.2l1.28-1l0.07-0.82l-3.82-9.43l-2.31-4.22l-1.6-2.14l-0.34-1.18l-2.46-5.93l-3.16-9.8
		l-0.61-4.96l-1.87-7.52l0.1-1.1l-0.93-1.59l-0.5-0.79l-0.04-4.57l-0.45-2l-0.05-0.01l-0.13-3.44l-0.41-2.06l0.19-2.17l0.43-1.53
		l2.73-5.5l0.69-2.68l1.24-2.18l1.52-2l1.4-0.6l0.74,0.13l0.37-0.75l-1.11-1.01l0.04-0.47l0.03-0.31l0.52-0.82l1.4-0.6l0.75-0.03
		l0.21-0.69l-0.26-0.49l-0.55-0.51l-0.24-0.64l0.17-0.22l0.31-0.13l1.13,0.7l1.24-0.54l0.85-1.11l0.31-0.13l-0.09-0.71l0.48-0.36
		l0.88,0.22l1.48,0.1l0.92-0.25l1.24-0.54l1.82-0.34l1.38-0.45l1.62-1.45l2.57-0.37l0.83-0.96l1.14-1.09l1.1-0.63l0.79-0.49
		l1.29,0.64l0.51-0.67l0.62-0.27l0.16-1.86l0.19-2.17l0.9-1.73l1.55-2.31l2.06-1.34l1.45-1.22l1.65-0.12l0.75-0.02l0,0l0.62-0.27
		l6.06,2.45l1.47,0.26l0.82-0.8l1.26-4.12l1.9-2.91l1.75-1.21l3.01-0.26l-0.13-0.24l0.05-0.62l1.15,0.55l2.8-3l3.01,1.38l1.05-0.01
		l2.59,1.12l2.36-1.32l1.21-1.87l-0.91-1.54l-0.68-0.75l-0.38,0.91l-2.81-0.27l-1.89-0.52l-2.99-1.69l-0.92-1.39l0.2-0.53l1.13,0.7
		l1.5,1.59l0.86,0.37l3.02-2.05l-0.36-1.04l0.83-0.96l-0.88-1.86l-0.98-0.77l-0.61-1.52l0.25,0.49l0.49-0.51l-0.22-2.59l0.47-0.2
		l0.55,0.51l0.38,0.73l-0.05,0.62l0.32,1.5l0.28,0.17l0.31-0.13l0.82,0.84l2.39,1.65l1.2-0.07l1.03,0.15l1.52-0.36l0.35-0.6
		l0.53-0.98l1.98-0.41l0.05-0.62l-0.2-1.11l-0.01-3.28l-0.54-2.3l-1.13-0.7l-1.43,0.91l-0.35,0.6l-0.88-0.22l-0.53-0.82l-0.23-0.79
		l0.41-1.22l-0.25-0.49l-2.11-1.47l-1.36,0.14l-2.32-0.79l-2.38-0.16l-0.72-0.29l-0.41-0.42l0.04-0.47l0.56-1.29l2.15,1.01l1.4-0.6
		l0.78-0.34l3.55,0.4l0.18-0.38l-0.36-1.04l0.58,0.2l0.68,0.75l0.71,0.44l1.16,0.39l0.09,0.71l0.57,0.35l-0.13-0.24l0.34-0.44
		l-0.54-2.3l-0.51-0.97l-0.48-1.28l-0.68-0.75l-0.94-1.24l-0.23-0.79l-0.5-1.13l-36.77-14.81l-38.54-16.73l-0.68-0.75l0,0l0.68,0.75
		l38.54,16.73l36.77,14.81l-0.07-0.86l0.28,0.18l1.38-0.45l-1.22-6.48l-0.12-0.4l-2.12-1.32l-1.56-0.97l-1.67,0.27l-1.61-0.35
		l-1.16-2.03l-1.35-1.65l0.08-0.93l2.05,2.09l0.85,0.53l1.86-0.81l-0.34-1.2l-1.5-1.59l-1.68-1.21l-0.65-1.06l0.18-0.38l0.57,0.35
		l1.17,0.24l0.07-0.78l-0.44-0.11l-0.63-1.37l0.37-0.76l1.94,1.7l0.48-0.36l0.21-0.69l-0.67-2.54l-2.44-2.82l0.03-0.31l0.75-0.03
		l0.47-0.2l-0.2-2.74l0.92-0.25l0.21-0.69l-1.14-2.34l-2.2-2.18l-3.09-3.88l-0.55-0.51l-1.05,0l-0.55-0.51l-1.04-1.79l-0.69-0.6
		l0.05-0.62l1.88,0.68l2.11,1.47l2.88,2.93l1.35,1.65l0.58,0.2l0.2-0.53l-0.64-1.21l-1.07-3.12l-1.25-1.1l-0.11-0.4l0.2-0.53
		l0.43,0.26l0.63-0.42l-1.5-1.59l-0.95-2.72l-0.69-4.03l-0.16-1.57l-0.1-2.19l0.87-3.06l-0.05-1.17l-0.51-0.97l-0.06-1.02l0.35-0.6
		l0.49-0.51l0.54-0.98l2.58-0.52l0.48-0.36l0.94-2.2l1.54,1.12l0.48,1.28l-1.33,1.47l-0.99,1.02l-0.96,0.71l-0.86,1.27l0.56,2.14
		l1.09,1.17l0.38,4.16l-0.2,0.53l-1.95,0.1l0.21,0.95l1.5,1.59l0.36,1.04l-0.58,1.44l0.34,1.19l-0.22,0.84l0.66,2.7l1.84,2.78
		l0.99,0.62l0.76-0.18l1.13,0.7l0.54,0.66l-0.39,1.06l0.94,2.87l-0.07,0.78l0.6,1.68l1.71,0.9l0.18,0.35l-0.07,0.05l0.41,0.42
		l-0.01,1.79l-0.22,0.84l-1.26,0.69l-0.68,0.89l-0.7,2.84l0.18,3.05l-0.26,2.95l0.52,2.61l0.69-2.68l1.11-2.42l2.08-3.29l0.87-1.42
		l0.58-1.44l0.08-2.57l0.22-0.84l2.13-0.48l0.35-0.6l0.21-0.69l0.39-1.06l1.71-5.81l-0.09-0.71l-0.12-2.04l-0.74-3.4l-1.53-2.92
		l-2.38-3.44l-0.74-1.77l-0.46-1.59l-1.65-4.95l-0.71-2.08l-1.38-2.98l-0.44-1.75l-0.03-1.33l0.15-0.07l0.59,0.04l0.7,2.23
		l0.99,2.25l0.88,1.86l3.09,3.88l0.96,0.93l2.22,1.87l0.83,0.68l-0.07,2.41l-0.28,1.46l0.78,1.3l0.95-0.56l1.2-1.71l0.33-2.08
		l1.66-1.91l1.05-1.8l1.28-7.71l2.03,4.04l1.04-1.64l0.14-1.55l0.15-6.77l-0.1-5.62l-0.85-2.17l-2.04-0.61l-0.76,0.18l-0.68-0.75
		l-0.26-0.49l0.35-0.6l2-0.72l1.12,0.86l2.55,1.58l0.92-0.25l2.46,0.87l2.08,0.14l1.48,0.1l1.71-0.74l6.94-0.76l1.81-0.19l1.53-0.36
		l1.17,0.24l0.79-0.49l1.5-0.05l0.9-0.09l0.86-1.27l0.2-0.53l-0.26-0.49l-1.55,0.67l-1.46-0.41l-1.77-0.28l-1.07,0.31l-0.68,0.89
		l-1.63-0.19l-0.24-0.64l0.68-0.89l1.26-0.69l1.48-1.53l-0.38-0.73l-1.24,0.54l-0.82,0.8l-1.09,0.47l-3.87-0.11l-4.9-0.26l-4-0.36
		l-1.48-0.1l-1.61,1.29l-0.79-1.15l-0.15-1.73l0.73-1.51l1.37-0.29l1.94,0.06l5.06-1.44l2.2-1.25l0.37,0.88l3.55,0.4l2.45,1.03
		l0.44,0.11l0.07-0.78l0.47-0.2l1.12,0.86l4.27,0.69l1.58,0.66l0.69,0.6l2.71-0.28l1.69-0.58l0.11-1.24l-0.36-1.04l0.16-1.86
		l1.24-0.54l0.38,0.73l-0.1,1.09l-0.21,2.33l0.36,1.04l0.86,0.37l0.12-1.39l0.57,0.35l0.93-0.4l1.29,0.64l0.16-0.07l0.48-0.36
		l1.89-1.12l1.49-1.69l0.43,0.26l0.27,1.97l-0.08,0.93l0.4,0.57l0.71,0.44l2.46-0.77l2.36,0.32l1.67-0.27l1.18,1.88l0.29-1.62
		l-0.97-4.2l-1.82-4.73l-0.57-0.35l-1.27-0.79l-0.22,0.84l1.63,1.83l1.15,2.19l0.53,0.82l-1.34,1.62l-1.92-0.21l-3.24-2.18
		l-1.12-2.5l-0.81-2.63l-2.56-4.86l-1.6-2.14l-1.84-1.14l-0.04-1.17l0.76-1.82l1-1.18l0.9-0.09l0.34-0.44l-0.34-1.19l-1.7-2.69
		l-0.36-2.68l0.11-1.24l0.12-1.4l0.32-0.29l0.35-0.6l0.45-1.69l0.5-2.3l1.35-1.78l1.04-3.28l1.54-0.52l0.21-0.69l-0.67-0.9
		l-0.52-2.61l3.7-1.3l0.53,0.82l0.43,1.9l0.38,0.73l0.48-0.36l0.15-1.71l0.37-0.75l2.57,1.27l1.24-0.54l0.11-1.24l0.48-0.36
		l1.27,0.79l1.05-0.01l0.48-0.36l0.79-2.13l-0.12-2.03l1.88-0.96l0.11-1.24l0.71,0.44l1.81,1.45l0.89,0.06l0.66-0.73l0.87-1.42
		l0.72,0.28l1.32-1.47l1.97,1.39l1.28,2.43l2.33-2.65l1.78,0.12l2.92-0.97l0.83-0.96l0.45-0.05l0.71,0.44l1.34,0.02l0.86-1.27
		L353.04,93.47z M-356.78-207.49L-356.78-207.49l50.85,132.32v0L-356.78-207.49z M-316.38-46.49L-316.38-46.49l-0.3-1.66l0,0
		L-316.38-46.49z M-317.09-41.85l-0.27-0.94L-317.09-41.85l1.82,3.09L-317.09-41.85z M-305.73-65.55L-305.73-65.55l0.15,0.58
		L-305.73-65.55z M-305.57-67.41l-0.8-2.8L-305.57-67.41L-305.57-67.41z M-303.61-55.74L-303.61-55.74l0.34-0.44l-0.12-2.03
		l-0.74-1.77l-0.79-1.14l-0.17-1.28l0.17,1.28l0.79,1.15l0.74,1.77l0.12,2.04L-303.61-55.74z M-287-258.56l-0.05-2.81l0.71,2.08
		L-287-258.56z M132.52,216.17l14.73,6.75L132.52,216.17L132.52,216.17z M157.75,207.78l0.46,0.03L157.75,207.78L157.75,207.78z
		 M145,210.17l1.1-0.34L145,210.17L145,210.17z M160.35,227.39l0.12-0.46L160.35,227.39L160.35,227.39z M164.19,227.01l0.36,0.05
		l0,0L164.19,227.01z M165.04,226.7l-0.31,0.23L165.04,226.7l0.57,0.35L165.04,226.7z M174.37,202.23l0.21-0.36L174.37,202.23
		L174.37,202.23z M170.69,199.95l4.03,1.69v0L170.69,199.95l-0.04,0.47l-0.11,0.31l0.11-0.31L170.69,199.95z M169.62,205.33
		l0.06-0.29L169.62,205.33l-2.09,0.01L169.62,205.33z M161.11,206.93l1.33,0.17l2.28,1.25v0l-2.28-1.25L161.11,206.93l-1.98,0.41
		l-0.08,0.23l0.08-0.23L161.11,206.93z M156.49,206.22l-0.09-0.09l-0.47,0.2l0.47-0.2L156.49,206.22z M137.29,209.48l0.61-0.5
		L137.29,209.48l-0.66,0.73l0.02,0.29l-0.02-0.29L137.29,209.48z M136.45,212.23l0.11-0.51L136.45,212.23l-1.24,0.54l-0.52-0.18
		l0.52,0.18L136.45,212.23z M134.63,212.57L134.63,212.57l-1.27-0.79L134.63,212.57z M185.02,237.14L185.02,237.14l-0.34,0.44
		L185.02,237.14z M189.35,244.05l0.13,1.75l0,0L189.35,244.05z M217.36,270.33l-11.26-16.66l-12.2-5.78l12.2,5.78L217.36,270.33z
		 M188.22,241.34l-1.56-2.53v0L188.22,241.34z M178.37,204.23l-0.12-0.06L178.37,204.23l0.44,0.14L178.37,204.23z"/>
            <g>

			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="390.2565" y1="191.638" x2="390.2565" y2="346.7639" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
			<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
		</linearGradient>
                <path class="st1" d="M415.94,480.13l-35.92-18.31c-5.9-3.01-10.69-10.94-10.69-17.69c0-9.34,6.64-13.55,14.79-9.4l9.31,4.74
			c2.49,1.27,4.51-0.02,4.51-2.86c0-4.89-3.48-10.64-7.75-12.82l-25.61-13.05v-8.07l25.61,13.05c8.16,4.16,14.79,15.14,14.79,24.48
			c0,7.29-5.18,10.58-11.55,7.34l-9.31-4.74c-4.27-2.18-7.75,0.03-7.75,4.92c0,2.3,1.64,5.01,3.65,6.03l35.92,18.31V480.13z"/>
	</g>

            <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="339.1567" y1="237.764" x2="339.1567" y2="76.4305" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st2" points="392.15,398.66 286.16,344.63 286.16,189.54 392.15,243.57 	"/>

            <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="539.6328" y1="157.5645" x2="594.4164" y2="12.0282" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <path class="st3" d="M641.32,349c1.37-4.77,2.11-10.15,2.11-16.12c0-34.49-24.42-74.9-54.55-90.26
		c-20.15-10.27-37.74-6.71-47.19,7.08c-7.21-11.47-16.92-21.2-27.61-26.66c-22.22-11.33-40.24,0.11-40.24,25.56
		c0,0.91,0.03,1.83,0.07,2.75c-27.88-11.01-49.71,7.24-49.71,43.02c0,37.92,24.52,81.16,54.77,96.58l154.07,78.54
		c30.25,15.42,54.77-0.16,54.77-34.79C687.82,403.29,667.64,366.99,641.32,349z"/>

            <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="517.4005" y1="177.4403" x2="572.1841" y2="31.904" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <path class="st4" d="M619.08,358.98c1.37-4.77,2.11-10.15,2.11-16.12c0-34.49-24.42-74.9-54.55-90.26
		c-20.15-10.27-37.74-6.71-47.19,7.08c-7.21-11.47-16.92-21.2-27.61-26.66c-22.22-11.33-40.24,0.11-40.24,25.56
		c0,0.91,0.03,1.83,0.07,2.75c-27.88-11.01-49.71,7.24-49.71,43.02c0,37.92,24.52,81.16,54.77,96.58l154.07,78.54
		c30.25,15.42,54.77-0.16,54.77-34.79C665.59,413.27,645.41,376.96,619.08,358.98z"/>

            <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="533.8759" y1="242.6388" x2="517.5587" y2="141.5973" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st5" points="601.81,474.53 438.94,391.51 438.94,310.42 601.81,393.44 	"/>

            <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="555.5912" y1="218.6025" x2="539.2739" y2="117.561" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st6" points="623.52,460.17 460.66,377.15 460.66,296.05 623.52,379.07 	"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -121.7299 284.7082)" class="st7" cx="463.42" cy="366.52" rx="8.23" ry="13.43"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -124.0251 295.0445)" class="st8" cx="481.3" cy="375.91" rx="4.12" ry="6.74"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -125.5617 302.3874)" class="st8" cx="494.06" cy="382.41" rx="4.12" ry="6.74"/>
            <polygon class="st8" points="561.13,438.23 553.9,434.54 553.9,387.18 561.13,390.87 	"/>
            <polygon class="st8" points="574.23,444.91 566.99,441.22 566.99,393.86 574.23,397.55 	"/>
            <polygon class="st8" points="588.02,451.93 580.78,448.24 580.78,400.89 588.02,404.58 	"/>

            <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="533.8759" y1="363.3958" x2="517.5587" y2="262.3543" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st9" points="601.81,595.29 438.94,512.27 438.94,431.18 601.81,514.19 	"/>

            <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="555.5912" y1="339.3595" x2="539.2739" y2="238.318" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st10" points="623.52,580.92 460.66,497.9 460.66,416.81 623.52,499.83 	"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -182.8038 301.2911)" class="st7" cx="463.42" cy="487.27" rx="8.23" ry="13.43"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -185.099 311.6274)" class="st8" cx="481.3" cy="496.67" rx="4.12" ry="6.74"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -186.6355 318.9703)" class="st8" cx="494.06" cy="503.17" rx="4.12" ry="6.74"/>
            <polygon class="st8" points="561.13,558.99 553.9,555.3 553.9,507.94 561.13,511.63 	"/>
            <polygon class="st8" points="574.23,565.66 566.99,561.97 566.99,514.62 574.23,518.31 	"/>
            <polygon class="st8" points="588.02,572.69 580.78,569 580.78,521.65 588.02,525.34 	"/>
            <polygon class="st8" points="307.34,315.27 307.34,306.98 370.3,339.08 370.3,347.37 	"/>
            <polygon class="st8" points="314.92,334.13 314.92,325.85 363.62,350.67 363.62,358.96 	"/>
            <g>
		<path class="st11" d="M349.53,256.4l-35.99-21.57l-4.77,0.99l31.33,26.81l-15.42,14.53l-12.65-6.19l-2.12,0.95l10.12,12.4
			l6.03,14.32l1.79-1.5l0.77-13.47l19.01-8.59l8.15,41.37l4.3-4.61l-2.88-42.23c0,0,15.94-9.51,11.64-16.62
			C364.55,245.88,349.53,256.4,349.53,256.4z"/>
	</g>
            <polygon class="st7" points="340.79,383.53 265.65,345.23 265.65,323.92 340.79,362.22 	"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -155.3739 238.5783)" class="st7" cx="361.65" cy="405.41" rx="8.23" ry="13.43"/>

            <ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -184.1807 277.2848)" class="st7" cx="418.52" cy="477.81" rx="8.23" ry="13.43"/>

            <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="336.313" y1="-4.1487" x2="426.6099" y2="-4.1487" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st12" points="398.71,172.62 381.86,138.06 364.6,155.19 371.45,164.65 377.51,158.64 377.51,194.67 386.12,199.06
		386.12,163.33 391.8,174.98 	"/>

            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="140.2766" y1="785.4407" x2="230.5735" y2="785.4407" gradientTransform="matrix(-1 -0.4452 0 -1 567.2739 1391.4353)">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <polygon class="st13" points="364.6,518.25 381.45,552.82 398.71,535.69 391.86,526.22 385.8,532.24 385.8,496.2 377.19,491.81
		377.19,527.55 371.51,515.9 	"/>
            <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-13.8361" y1="225.0354" x2="320.0715" y2="327.0902">
		<stop  offset="0" style="stop-color:#26323E;stop-opacity:0"/>
                <stop  offset="1" style="stop-color:#5F646E"/>
	</linearGradient>
            <path class="st14" d="M276.64,167.62c-1.4-0.87-2.64-0.33-2.77,1.2c-0.08,0.88,0.23,1.89,0.76,2.76
		c-1.45,5.37-4.74,15.34-9.86,25.45c-4.33,8.55-9.3,16.01-14.78,22.16c-4.93,5.54-10.27,10.02-15.96,13.4
		c2.68-4.26,6.76-11.32,13.07-23c6.16-11.41,12.11-22.93,14.35-27.28c1.2,0.5,2.2-0.08,2.32-1.44c0.14-1.53-0.89-3.47-2.28-4.34
		c-1.4-0.87-2.64-0.33-2.77,1.2c-0.06,0.69,0.12,1.47,0.46,2.19c-2.2,4.28-7.99,15.49-14.04,26.7c-4.49,8.33-8.24,15.02-11.14,19.91
		c-1.23,2.07-2.24,3.7-3.06,4.96l9.89-42.81c0.96,0.17,1.71-0.42,1.81-1.59c0.14-1.53-0.89-3.47-2.28-4.34
		c-1.4-0.87-2.64-0.33-2.77,1.2c-0.08,0.89,0.24,1.93,0.79,2.81l-10.39,45c-5.43-18.4-21.34-68.12-25.34-80.6
		c0.27-0.27,0.44-0.68,0.49-1.2c0.14-1.53-0.89-3.47-2.28-4.34c-1.4-0.87-2.64-0.33-2.77,1.2c-0.13,1.52,0.88,3.45,2.26,4.32
		c8.13,25.34,21.65,67.86,25.78,82.1c-3.63-5.08-11.86-17.04-30.45-44.92c-12.29-18.42-24.36-36.7-28.67-43.23
		c0.02-0.09,0.03-0.18,0.04-0.28c0.14-1.53-0.89-3.47-2.28-4.34c-1.4-0.87-2.64-0.33-2.77,1.2c-0.14,1.53,0.89,3.47,2.28,4.34
		c0.37,0.23,0.74,0.36,1.07,0.4c4.44,6.73,17.16,25.97,29.85,44.99c9.2,13.78,16.59,24.76,21.96,32.62c2.15,3.14,3.98,5.8,5.49,7.95
		c-11.31-8.04-26.73-23.15-45.13-44.25c-13.05-14.96-24.04-28.87-28.23-34.24c0.11-1.52-0.9-3.43-2.29-4.29
		c-1.4-0.87-2.64-0.33-2.77,1.2c-0.14,1.53,0.89,3.47,2.28,4.34c0.48,0.3,0.95,0.43,1.35,0.41c4.22,5.41,15.28,19.41,28.41,34.46
		c9.83,11.28,18.89,20.94,26.92,28.71c8.41,8.14,15.72,14.24,21.8,18.18c-2.84-0.33-7.97-1.6-17.12-4.83
		c-9.04-3.19-20.92-7.92-35.31-14.05c-20.18-8.6-39.75-17.6-46.17-20.58c-0.4-0.94-1.09-1.82-1.91-2.33
		c-1.4-0.87-2.64-0.33-2.77,1.2c-0.14,1.53,0.89,3.47,2.28,4.34c1.06,0.66,2.03,0.5,2.5-0.28c6.44,2.99,26.03,12,46.25,20.61
		c14.44,6.15,26.38,10.9,35.47,14.12c5.42,1.92,9.89,3.3,13.28,4.12c0.94,0.23,1.81,0.41,2.6,0.56c-1.25,0.97-2.94,1.97-5.14,2.69
		c-3.39,1.1-7.3,1.33-11.64,0.67c-4.85-0.73-10.24-2.58-16.05-5.49c-0.39-1-1.11-1.94-1.97-2.47c-1.4-0.87-2.64-0.33-2.77,1.2
		c-0.14,1.53,0.89,3.47,2.28,4.34c1.02,0.63,1.96,0.51,2.44-0.19c6.12,3.06,11.81,5,16.93,5.76c3.97,0.59,7.6,0.48,10.84-0.33
		c-2.58,2.11-5.89,4.27-10.06,6.14c-7.96,3.56-17.44,5.32-28.15,5.22c-12.85-0.12-27.54-2.92-43.68-8.33
		c-0.41-0.81-1.03-1.54-1.75-1.99c-1.4-0.87-2.64-0.33-2.77,1.2c-0.14,1.53,0.89,3.47,2.28,4.34c1.16,0.72,2.21,0.47,2.61-0.51
		c16.36,5.48,31.27,8.33,44.32,8.44c10.94,0.1,20.61-1.7,28.76-5.34c3.32-1.49,6.12-3.15,8.44-4.83
		c-4.01,5.5-10.11,12.46-18.43,18.29c-10.81,7.58-23.1,11.74-36.52,12.37c-16.44,0.77-34.66-3.78-54.16-13.54
		c-0.4-0.99-1.11-1.91-1.95-2.44c-1.4-0.87-2.64-0.33-2.77,1.2c-0.14,1.53,0.89,3.47,2.28,4.34c1.03,0.64,1.97,0.51,2.45-0.21
		c19.9,9.96,38.51,14.61,55.31,13.81c13.7-0.66,26.24-4.91,37.26-12.66c8.76-6.15,15.09-13.53,19.15-19.21
		c-4.8,12.71-11.72,29.9-16.58,37.01c-1.21-0.51-2.21,0.06-2.34,1.43c-0.14,1.53,0.89,3.47,2.28,4.34c1.4,0.87,2.64,0.33,2.77-1.2
		c0.06-0.68-0.11-1.44-0.44-2.16c4.97-7.38,11.8-24.29,16.57-36.94c-1.63,8.62-3.99,19.86-7.19,32.49
		c-4.24,16.72-9.11,32.57-14.48,47.12c-6.51,17.63-13.77,33.36-21.6,46.8c-1.29-0.65-2.38-0.09-2.51,1.35
		c-0.14,1.53,0.89,3.47,2.28,4.34c1.4,0.87,2.64,0.33,2.77-1.2c0.05-0.61-0.08-1.3-0.35-1.95c7.9-13.54,15.22-29.41,21.79-47.18
		c5.4-14.61,10.29-30.54,14.55-47.35c2.14-8.44,3.9-16.27,5.32-23.11c-2.65,30.59-7.79,86.51-16.74,172.59l2.51,1.6
		c13.49-129.58,18.32-190.86,19.03-200.24c0.34-0.1,0.53-0.44,0.54-0.91c8.08-3.59,15.57-9.24,22.29-16.79
		c5.57-6.26,10.62-13.84,15.01-22.52c5.18-10.24,8.52-20.35,9.99-25.81c0.98,0.19,1.75-0.4,1.85-1.58
		C279.06,170.43,278.04,168.49,276.64,167.62z"/>
            <g>

			<ellipse transform="matrix(0.8627 -0.5058 0.5058 0.8627 -88.3526 147.9164)" class="st7" cx="228.21" cy="236.66" rx="6.21" ry="10.14"/>
	</g>
            <g>
		<g>
			<g>
				<g>

						<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="222.1212" y1="134.1399" x2="233.0515" y2="134.1399" gradientTransform="matrix(1 0.4452 0 1 0 1.7479)">
						<stop  offset="0" style="stop-color:#26323E"/>
                            <stop  offset="1" style="stop-color:#5F646E"/>
					</linearGradient>
                    <path class="st15" d="M229.45,234.06c0,0,2.7-1.89,3.47-0.61c0.77,1.28-2.09,2.99-2.09,2.99l0.52,7.59l-0.77,0.83l-1.47-7.44
						l-3.42,1.54l-0.14,2.42l-0.32,0.27l-1.08-2.58l-1.82-2.23l0.38-0.17l2.27,1.11l2.77-2.61l-5.63-4.82l0.86-0.18L229.45,234.06z"
                    />
				</g>
			</g>
		</g>
	</g>
</g>
</svg>
</template>
